.el-select[data-v-306702b0] {
  width: 100%;
  max-width: 500px;
}
.update-item[data-v-306702b0] {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 32px;
  background-color: #ddd;
  max-width: 500px;
  white-space: nowrap;
}
.w-150[data-v-306702b0] {
  width: 150px !important;
}
